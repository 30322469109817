import React, { useEffect, useState } from 'react';
import { Col, Collapse, message, Row, Steps } from 'antd';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box } from 'atoms';
import {
	getOrdersById,
	getReturnToFCReasonListNew,
	orderComplete,
	storeOrderDetails,
	rfcBlockerStatus,
} from 'store/fulfilment/returnToFCSlice';
import { RTCDeliveryForm, Debug } from 'components/forms';
import { SelectOptions, UserPersmission } from 'utils';
import {
	RTCSetInitialValues,
	RTCInitialValues,
	RTCFormSchema,
	RTCRefinedPayload,
	RTCUpdateOrderDetails,
	RTCReturnfilter,
} from '../helper';
import MapComponent from 'components/map/MapComponent';
import { retailerKYC } from 'store/fulfilment/salesOrderSlice';
import MapHeaderPannel from './MapHeaderPannel';
import { ACL, PanelData, SalesAccordionHeader } from 'components/common';
import { returnToFcShowPanelNew } from 'pages/fulfillment/sales-order/salesOrderRFCConfig';

const { Step } = Steps;
const { Panel } = Collapse;

const rolesAllowedToUpload = ['Transport Manager', 'System Admin'];

export default function RTCDeliveryPage() {
	const { id: orderId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const locationRoutes = useLocation();
	const { invoiceId, orderDetails: localOrderDetails } = useSelector((s) => s?.returnToFC);
	const searchParams = new URLSearchParams(locationRoutes.search);
	const searchVal = searchParams.get('filter') ?? '';
	const quid = searchParams.get('quid') ?? '';

	const [data, setData] = useState({ type: 'NotAsked' });
	const [returnReason, setReturnReason] = useState([]);
	const [mismatchReason, setmismatchReason] = useState([]);
	const [initialValues, setFormInitial] = useState(RTCInitialValues);
	const [changeStatus, setChangeStatus] = useState(localOrderDetails?.new_orderStatus);
	const { auth } = useSelector((state) => state?.auth);
	const [rfcEditBlock, setRfcEditBlock] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState({
		lat: null,
		lng: null,
	});
	const [newSelectedLocation, setNewSelectedLocation] = useState({
		lat: null,
		lng: null,
	});
	const [kycDetails, setKycDetails] = useState(null);

	const uploadAllowed = rolesAllowedToUpload.includes(auth?.user.groups[0].name);

	function getMapDetails() {
		dispatch(retailerKYC(orderId)).then((res1) => {
			console.log('res1 kyc details ', res1);
			setKycDetails(res1?.data);
			setSelectedLocation({
				// eslint-disable-next-line no-unsafe-optional-chaining
				lat: +res1?.data?.store_latitude,
				// eslint-disable-next-line no-unsafe-optional-chaining
				lng: +res1?.data?.store_longitude,
			});
			if (res1?.data?.outside_fence || res1?.data?.approval_status === 'rejected') {
				setNewSelectedLocation({
					// eslint-disable-next-line no-unsafe-optional-chaining
					lat: +res1?.data?.kyc_latitude || +res1?.data?.latitude,
					// eslint-disable-next-line no-unsafe-optional-chaining
					lng: +res1?.data?.kyc_longitude || +res1?.data?.longitude,
				});
			}
		});
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		setData({ type: 'Loading' });
		dispatch(getOrdersById(orderId)).then((res) => {
			console.log('res res ', res);

			const { order, orderDetail, invoice, warehouseDueFlag } = res;
			const mismatchFilter = { is_mismatch: true, user_fcs_only: true };

			Promise.all([
				dispatch(getReturnToFCReasonListNew({ ...RTCReturnfilter(order?.status) })),
				dispatch(getReturnToFCReasonListNew({ ...mismatchFilter })),
			]).then(([returnReasonList, mismatchlist]) => {
				setReturnReason(SelectOptions(returnReasonList, 'name', 'id'));
				setmismatchReason(SelectOptions(mismatchlist, 'name', 'id'));
				setFormInitial(
					RTCSetInitialValues(
						{ status: invoice?.status, billtype: invoice?.billtype },
						order,
						orderDetail,
						warehouseDueFlag
					)
				);
				setData({ type: 'Success', data: res || {} });
				getMapDetails();
			});
		});
	}, [dispatch, orderId]);

	// after page unmount remove storeOrderDetails details
	useEffect(
		() => () => {
			dispatch(storeOrderDetails({}));
			// localStorage.removeItem('storeDetails');
		},
		[]
	);

	const [dlTableData, setDlTableData] = useState(0);

	const onClickGetData = (event) => {
		setDlTableData(event);
	};

	useEffect(() => {
		if (!isEmpty(orderId)) {
			dispatch(rfcBlockerStatus(orderId)).then((res) => setRfcEditBlock(res?.rfc_edit_block));
		}
	}, [dispatch, orderId]);

	// eslint-disable-next-line consistent-return
	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);

			if (['VA', 'XD'].includes(values?.order_status)) {
				return navigate(
					`/logistics-management/return-to-fc-new/${+invoiceId}/show?quid=${quid}&filter=${searchVal}`
				);
			}

			if (!isEmpty(orderId)) {
				const res = await dispatch(rfcBlockerStatus(orderId));
				setRfcEditBlock(res?.rfc_edit_block);

				if (res?.rfc_edit_block) {
					setSubmitting(false);
					message.error(
						'You cannot Edit or Update the details until Delivery Person completes Delivery activity in Mobile Application'
					);
					return true;
				}
			}

			const r = await dispatch(
				orderComplete(RTCRefinedPayload(values, 'delivery', dlTableData), orderId)
			);

			if (r.data) {
				console.log('ordervalues', values);
				message.info('Delivery details updated Successfully');
				console.log('thisStatus', values?.order_status);

				if (!['DA', 'CA'].includes(values?.order_status)) {
					return navigate(
						`/logistics-management/return-to-fc-new/${+orderId}/collection?quid=${quid}&filter=${searchVal}`
					);
				}
			}

			return navigate(
				`/logistics-management/return-to-fc-new/${+invoiceId}/show?quid=${quid}&filter=${searchVal}`
			);
		} catch (e) {
			if ('response' in e && e?.response?.status === 422) {
				message.error('Error while updating the order data');
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleSearch = (query, setHiddenRows, values) => {
		const hiddenRows = new Set();
		values?.searchOrderDetails?.forEach((x) => {
			if (!x?.product_name?.toLowerCase()?.includes(query.toLowerCase())) {
				hiddenRows.add(x.id);
			}
		});
		setHiddenRows(hiddenRows);
	};

	const handleOrderStatus = async (val, setFieldValue, hascancelReason) => {
		console.log('hascancelReason', hascancelReason);
		if (hascancelReason) {
			setFieldValue('reason', hascancelReason);
			// setFieldValue('orderDetails', RTCUpdateOrderDetails(val, data?.data, hascancelReason));
			return true;
		}

		dispatch(getReturnToFCReasonListNew({ ...RTCReturnfilter(val) })).then((res) =>
			setReturnReason(SelectOptions(res, 'name', 'id'))
		);

		setFieldValue('reason', undefined);
		setFieldValue('order_status', val);
		setFieldValue('orderDetails', RTCUpdateOrderDetails(val, data?.data)); // this is for resetting value on delivery status change.
		return true;
	};

	if (data.type === 'Loading' || data.type === 'NotAsked') {
		return (
			<Box className="RTCDetailsPage" background="#F5F5F5" textAlign="center">
				Loading...
			</Box>
		);
	}

	console.log(data, 'datadata');

	return (
		<Box className="RTCDetailsPage" background="#F5F5F5">
			<Box width="30%" margin="auto" marginBottom="30px">
				<Steps current={2} labelPlacement="vertical">
					<Step
						className="stepIcons"
						status="process"
						description="Delivery Details"
						title=""
					/>
					<Step status="wait" description="Collection Details" title="" />
				</Steps>
			</Box>
			<Box padding="20px 30px">
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={RTCFormSchema('delivery')}
					enableReinitialize
				>
					{({ values, setFieldValue, errors }) => (
						<Form layout="vertical">
							{((selectedLocation?.lat && selectedLocation?.lng) ||
								(newSelectedLocation?.lat && newSelectedLocation?.lng)) && (
								<Box marginBottom="30px">
									<Collapse
										bordered={false}
										expandIconPosition="end"
										className="custom"
									>
										<Panel
											header={
												<MapHeaderPannel
													HeaderData={{
														KycDetails: kycDetails,
													}}
												/>
											}
											key="mapDetails"
										>
											<div style={{ overflow: 'hidden' }}>
												<div
													style={{
														height: '300px',
														float: 'left',
														width: '100%',
													}}
												>
													<MapComponent
														selectedLocation={selectedLocation}
														newSelectedLocation={newSelectedLocation}
														kycDetails={kycDetails}
													/>
												</div>
											</div>
										</Panel>
									</Collapse>
								</Box>
							)}
							<Box className="RTCDetailsPageNoBorder" marginBottom="30px">
								<Collapse
									expandIconPosition="right"
									defaultActiveKey={['accoridon']}
									ghost
								>
									<Panel
										header={
											<SalesAccordionHeader
												title="Order Details:"
												status={data?.data?.order?.status}
												invoice={data?.data?.invoice?.invoice_no}
												storeName={data?.data?.invoice?.store_name}
												isQuid={data?.data?.order?.bnpl_enabled}
											/>
										}
										key="accoridon"
										className="site-collapse-custom-panel"
									>
										<Row gutter={6}>
											{returnToFcShowPanelNew(data?.data).map(
												(x) =>
													!x.hidden && (
														<Col key={x?.title} xs={6}>
															{console.log('data', data)}
															<PanelData
																title={x?.title}
																value={x?.value}
																additionalValue={
																	x?.additionalValue || ''
																}
																type={x?.type || ''}
																infoDetails={x?.infoDetails}
																brandInfo={data?.Brand}
															/>
															{x.hidden}
														</Col>
													)
											)}
										</Row>
									</Panel>
								</Collapse>
							</Box>
							<Box marginBottom="30px">
								<Collapse
									bordered={false}
									defaultActiveKey={['delivery_details']}
									expandIconPosition="end"
								>
									<Panel header="Delivery Details" key="delivery_details">
										<RTCDeliveryForm
											onClickGetData={onClickGetData}
											values={values}
											returnReason={returnReason}
											mismatchReason={mismatchReason}
											setFieldValue={setFieldValue}
											handleOrderStatus={handleOrderStatus}
											errors={errors}
											orderData={data?.data}
											handleSearch={handleSearch}
											changeCurrentStatus={[changeStatus, setChangeStatus]}
											uploadAllowed={uploadAllowed}
										/>
									</Panel>
								</Collapse>
							</Box>
							<ACL allowedGroups={UserPersmission.returnToFC_create}>
								<Box textAlign="center">
									<SubmitButton
										disabled={
											!isEmpty(changeStatus) ||
											!uploadAllowed ||
											data?.data?.order?.return_verified_by ||
											rfcEditBlock
										}
									>
										Update
									</SubmitButton>
								</Box>
							</ACL>
							<Debug show />
						</Form>
					)}
				</Formik>
			</Box>
		</Box>
	);
}
