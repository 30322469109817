import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Select } from 'formik-antd';
import { isEmpty } from 'lodash';
import { InputNumber } from 'antd';
import { Box, Button, Flex, Text } from 'atoms';
import { ACLBoolean, SearchBar } from 'components/common';
import {
	SelectFilterLabelOptions,
	DeliverStatusOptionsForWarehouseDue,
	DeliverStatusOptions,
	getstatus,
	UserPersmission,
} from 'utils';
import { rfcBlockerStatus } from 'store/fulfilment/returnToFCSlice';
import Rupee from 'components/Rupee';
import { Thead, Badge, ErrorMessage, RFCTable } from 'components/forms/return-to-fc';
import './style.scss';

const getValues = (val) => {
	if (val === 'XD' || val === 'OD') {
		return 'Out for Delivery';
	}
	if (val === 'VA') {
		return 'Vehicle Allocated';
	}
	return val;
};
export default function RTCDeliveryForm({
	values,
	returnReason,
	setFieldValue,
	handleOrderStatus,
	errors,
	handleSearch,
	orderData,
	changeCurrentStatus,
	onClickGetData,
	uploadAllowed,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { invoiceId } = useSelector((s) => s?.returnToFC);
	const statusPD = values?.order_status === 'PD';
	const statusPDCA = values?.order_status === 'PD' || values?.order_status === 'CA';
	const [changeStatus, setChangeStatus] = changeCurrentStatus;
	const [rfcEditBlock, setRfcEditBlock] = useState(false);
	const [tableData, setTableData] = useState(values?.orderDetails);
	const [disableInputSave, setDisableInputSave] = useState(false);

	const disableInput = ACLBoolean(UserPersmission.returnToFC_create);

	console.log('vallll', values?.orderDetails[0]?.order_id);
	const initialTableValues = values?.orderDetails;

	const handleChangeOrderStatus = (val) => {
		if (val === orderData?.order?.status) {
			return handleOrderStatus(val, setFieldValue, null);
		}

		setChangeStatus(val);
		return setFieldValue('order_status', values?.order_status);
	};

	useEffect(() => {
		setDisableInputSave(disableInput);
	}, [disableInput]);

	useEffect(() => {
		if (!isEmpty(values?.orderDetails)) {
			dispatch(rfcBlockerStatus(values?.orderDetails[0]?.order_id)).then((res) =>
				setRfcEditBlock(res?.rfc_edit_block)
			);
		}
	}, [dispatch, values?.orderDetails]);

	useEffect(() => {
		console.log('thischange', tableData);
		onClickGetData(tableData);
	}, [tableData]);

	useEffect(() => {
		setTableData(initialTableValues);
	}, [initialTableValues]);

	console.log('rfcEditBlock', rfcEditBlock);

	const columns = [
		{
			title: 'Product Name',
			dataIndex: 'product_name',
			key: 'product_name',
			render: (product_name, record) => {
				console.log('recorddd', record);
				return (
					<Box>
						<Text>
							{product_name}({record?.product_code})
						</Text>
						<Rupee>{record?.product_MRP || 0}</Rupee>
					</Box>
				);
			},
		},
		{
			title: <Thead>Ord Qty</Thead>,
			dataIndex: 'ordered_qty',
			key: 'ordered_qty',
			render: (ordered_qty, record) => (
				<>
					<Text>{ordered_qty}</Text>
					{record?.free_qty !== 0 && (
						<Flex alignItems="center">
							<Badge free>Free : {record?.free_qty}</Badge>
							<span> </span>
						</Flex>
					)}
				</>
			),
		},
		{
			title: <Thead>Picked Qty</Thead>,
			dataIndex: 'picked_qty',
			key: 'picked_qty',
			render: (picked_qty, record) => (
				<>
					<Text>{picked_qty}</Text>
					{record?.warehouseDue !== 0 && (
						<Flex alignItems="center">
							<Badge due>Due: {record?.warehouseDue}</Badge> <span></span>
						</Flex>
					)}
				</>
			),
		},
		{
			title: <Thead>Delivered Qty</Thead>,
			// dataIndex: 'delivered_qty',
			key: 'delivered_qty',
			className: 'border',
			render: (delivered_qty, record) => {
				console.log('this', record?.delivered_qty);
				console.log('thisRecord', record);
				// const returnedQty = record?.return_qty || 0;
				// const pickedQty = record?.picked_qty || 0;
				// const deliveredQty = pickedQty - returnedQty;

				return (
					<InputNumber
						min={0}
						// max={delivered_qty}
						disabled
						// eslint-disable-next-line no-nested-ternary
						// value={statusCA ? 0 : statusDL ? record?.delivered_qty : deliveredQty}
						value={record?.delivered_qty || 0}
						// value={record?.delivered_qty > 0 ? record?.delivered_qty : 0}
					/>
				);
			},
		},
		{
			title: <Thead>Total Returned Qty</Thead>,
			className: 'pl-increase',
			// dataIndex: 'return_qty',
			key: 'return_qty',
			render: (return_qty, record, index) => {
				const pickedQty = record?.picked_qty || 0;
				// const badQty = record?.bad_qty;
				const missingQty = record?.missing_qty;

				return (
					<InputNumber
						min={0}
						max={(record?.picked_qty || 0) - (record?.missing_qty || 0)}
						// eslint-disable-next-line no-nested-ternary
						value={record?.return_qty || 0}
						// value={record?.return_qty}
						disabled={
							!statusPDCA ||
							values?.return_verified_by ||
							rfcEditBlock ||
							!disableInputSave
						}
						onChange={(value) => {
							console.log('RTNVAL', value);
							const deliveryQty = pickedQty - value - missingQty;
							// const goodQty = value - badQty;
							setTableData(
								tableData.map((roww, i) =>
									i === index
										? {
												...record,
												return_qty: value,
												delivered_qty: deliveryQty,
												// good_qty: goodQty,
												bad_qty: 0,
												good_qty: value,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},
		{
			title: <Thead>Bad Qty</Thead>,
			// dataIndex: 'bad_qty',
			key: 'bad_qty',
			render: (bad_qty, record, index) => {
				const returnedQty = record?.return_qty || 0;
				return (
					<InputNumber
						min={0}
						max={returnedQty}
						disabled={
							record?.return_qty === 0 ||
							!statusPDCA ||
							values?.return_verified_by ||
							rfcEditBlock ||
							!disableInputSave
						}
						// value={!statusPD ? 0 : record?.bad_qty || 0}
						value={record?.bad_qty || 0}
						onChange={(value) => {
							const goodQuan = returnedQty - value;
							setTableData(
								tableData.map((roww, i) =>
									i === index
										? { ...record, bad_qty: value, good_qty: goodQuan }
										: roww
								)
							);
						}}
					/>
				);
			},
		},
		{
			title: <Thead>Good Qty</Thead>,
			className: 'border',
			// dataIndex: 'good_qty',
			key: 'good_qty',
			render: (good_qty, record) => (
				<InputNumber
					min={0}
					// max={record?.return_qty}
					disabled
					// eslint-disable-next-line no-nested-ternary
					value={record?.good_qty || 0}
					// value={record?.good_qty}
				/>
			),
		},
		{
			title: <Thead>Missing Qty</Thead>,
			className: 'pl-increase',
			// dataIndex: 'missing_qty',
			key: 'missing_qty',
			render: (missing_qty, record, index) => {
				const returnedQty = record?.return_qty || 0;
				const pickedQty = record?.picked_qty || 0;
				// const deliveredQty = record?.delivered_qty || 0;

				return (
					<InputNumber
						min={0}
						max={(record?.picked_qty || 0) - (record?.return_qty || 0)}
						value={record?.missing_qty || 0}
						disabled={
							!statusPDCA ||
							values?.return_verified_by ||
							rfcEditBlock ||
							!disableInputSave
						}
						onChange={(value) => {
							const total = value + returnedQty;
							const deliveryQty = pickedQty - total;

							setTableData(
								tableData.map((roww, i) =>
									i === index
										? {
												...record,
												missing_qty: value,
												delivered_qty: deliveryQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},
		{
			title: 'Reason',
			// dataIndex: 'return_reason',
			key: 'return_reason',
			render: (return_reason, record, index) => (
				<>
					<Select
						name={`return_reason${index}`}
						style={{
							width: 200,
						}}
						disabled={
							record?.return_qty === 0 ||
							!statusPD ||
							values?.return_verified_by ||
							rfcEditBlock
						}
						value={statusPD ? record?.return_reason : undefined}
						placeholder="Select Reason"
						options={returnReason}
						onChange={(value) => {
							setTableData(
								tableData.map((reasonRow, i) =>
									i === index ? { ...record, return_reason: value } : reasonRow
								)
							);
						}}
					/>
					<Text style={{ color: '#ff4139', fontSize: '11px', fontWeight: '500' }}>
						{statusPD && record?.return_qty > 0 && !record?.return_reason
							? 'Reason is required'
							: ''}
					</Text>
				</>
			),
		},
	];

	const [hiddenRows, setHiddenRows] = useState(new Set());

	return (
		<Box padding="20px 30px">
			<Flex marginBottom="20px">
				<Box flex="0 0 300px">
					<SearchBar
						onSearch={(query) => handleSearch(query, setHiddenRows, values)}
						style={{ width: '260px' }}
						placeholder="Search Product Name"
					/>
				</Box>
				<Box flex="0 0 200px">
					<Select
						name="order_status"
						showSearch
						style={{ marginRight: '1rem', width: '200px' }}
						options={
							values?.warehouseDueFlag
								? DeliverStatusOptionsForWarehouseDue
								: DeliverStatusOptions
						}
						onChange={handleChangeOrderStatus}
						filterOption={SelectFilterLabelOptions}
						placeholder="Select Status"
						value={getValues(values?.order_status)}
						disabled={!uploadAllowed || values?.return_verified_by || rfcEditBlock}
					/>
				</Box>
				{['CA', 'DA'].includes(values?.order_status) && (
					<Box flex="0 0 200px">
						<Select
							name="reason"
							showSearch
							style={{ width: '200px' }}
							options={returnReason}
							filterOption={SelectFilterLabelOptions}
							onChange={(val) =>
								handleOrderStatus(values?.order_status, setFieldValue, val)
							}
							placeholder="Select Cancel Reason"
							disabled={values?.return_verified_by || rfcEditBlock}
						/>
						{errors?.reason && <ErrorMessage>{errors?.reason}</ErrorMessage>}
					</Box>
				)}
				{changeStatus && (
					<Box marginLeft="10px">
						<Box as="span" lineHeight="17px">
							Warning - You are about to change the status from{' '}
							{getstatus(orderData?.order?.status)} to {getstatus(changeStatus)},{' '}
							{orderData?.order?.status === 'PD' &&
								`All the Partial Delivery data will be cleared, ${' '}`}
							Do you want to continue?{' '}
						</Box>
						<Button
							onClick={() => {
								handleOrderStatus(changeStatus, setFieldValue, null);
								setChangeStatus(null);
								console.log('tableData', tableData);
								console.log('orderData', initialTableValues);
							}}
							minWidth="auto"
							minHeight="auto"
							padding="6px 10px"
							backgroundColor="#0A9600 !important"
						>
							Yes
						</Button>{' '}
						<Button
							minWidth="auto"
							minHeight="auto"
							padding="6px 10px"
							backgroundColor="#FF4747 !important"
							onClick={() =>
								navigate(
									`/logistics-management/return-to-fc-new/${+invoiceId}/show`
								)
							}
						>
							No
						</Button>
					</Box>
				)}
			</Flex>
			<RFCTable
				rowKey={(record) => `${record?.id}`}
				columns={columns}
				dataSource={tableData}
				pagination={false}
				rowClassName={(record) => hiddenRows.has(record.id) && 'custom-hide'}
			/>
		</Box>
	);
}

RTCDeliveryForm.propTypes = {
	values: PropTypes.object,
	returnReason: PropTypes.array,
	setFieldValue: PropTypes.func,
	orderData: PropTypes.object,
	handleOrderStatus: PropTypes.func,
	errors: PropTypes.object,
	handleSearch: PropTypes.func,
	changeCurrentStatus: PropTypes.array,
	onClickGetData: PropTypes.func,
	uploadAllowed: PropTypes.bool,
};
