/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { UpCircleFilled } from '@ant-design/icons';
import { message, Collapse, Table } from 'antd';
import styledComponents from 'styled-components';
import * as SVGICONS from 'utils/Svg';
import { Box, Flex, Text } from 'atoms';
import {
	failedViewDetails,
	getBrandSalesReturnFileUploadList,
	getUploadViewDetails,
} from 'store/UploadSlice';
import { BrandSalesReturnListColumns, UploadShowDetails, getFileId } from 'utils';

const TextWrap = styledComponents(Box)`
	flex: 0 0 12%;
`;

const CollapseStyling = {
	border: '1px solid #B9B9B9',
	borderLeft: '2px solid #CA5010',
	borderRadius: '2px',
	marginTop: '1rem',
};

const iconPosition = 'right';
const { Panel } = Collapse;

const collapseIcon = ({ isActive }) => (
	<UpCircleFilled
		style={{
			fontSize: '32px',
			color: '#FAF9F8',
			backgroundColor: '#CA5010',
			borderRadius: '50%',
		}}
		rotate={isActive ? 0 : 180}
	/>
);
const onChange = (key) => {
	console.log(key);
};

export function SalesReturnUploadView() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const fileType = searchParams.get('fileType');

	// const [failedRowData, setFailedRowData] = useState({});
	const [data, setData] = useState({});
	const [rowData, setRowDate] = useState({});
	const [status, setStatus] = useState(false);
	const [failedrowData, setfailedrowData] = useState({});
	const [showFailed, setFailedStatus] = useState(false);

	useEffect(() => {
		if (!isEmpty(id)) {
			dispatch(getUploadViewDetails({ id })).then((res) => {
				setData(res);
			});
		} else {
			message.error('Something went wrong!');
			navigate('/uploads');
		}
	}, [dispatch, id, fileType]);

	useEffect(() => {
		dispatch(getBrandSalesReturnFileUploadList({ id, fileType })).then((res) => {
			setRowDate(res?.rows);
			setStatus(true);
		});
		dispatch(failedViewDetails({ id, fileType, offset: 0, limit: 10, current: 1 })).then(
			(res) => {
				setfailedrowData(res?.rows);
				setFailedStatus(true);
			}
		);
	}, [dispatch, fileType]);

	return (
		<>
			{/* Upload View - details box */}
			<Collapse
				bordered={false}
				expandIconPosition={iconPosition}
				expandIcon={collapseIcon}
				defaultActiveKey={['1']}
				onChange={onChange}
				style={CollapseStyling}
			>
				<Panel
					header={
						<Flex m={3} style={{ alignItems: 'center' }}>
							<SVGICONS.CsvIcons style={{ marginRight: '0' }} />
							<Box mr={3}>{data?.fileName || ''}</Box>
							<Box>
								{/* <Button onClick={handleClick}>Download</Button> */}
								{getFileId({ data }).map((x) => (
									<TextWrap key={x.id}>
										<Flex alignItems="center" justifyContent="space-around">
											<Text
												color="#5B5B5B"
												fontWeight="600"
												fontSize="12px"
												mr={1}
											>
												{x.label}:{' '}
											</Text>
											<Text fontWeight="400" fontSize="16px">
												{x.value}
											</Text>
										</Flex>
									</TextWrap>
								))}
							</Box>
						</Flex>
					}
					key="1"
				>
					<Flex ml={3} flexWrap="wrap" style={{ gap: '24px' }}>
						{UploadShowDetails({ data }).map((e) => (
							<TextWrap mb={2} key={e.id}>
								<Text mb={3} color="#5B5B5B" fontWeight="600" fontSize="12px">
									{e.label}
								</Text>
								<Text fontWeight="400" fontSize="16px">
									{e.value}
								</Text>
							</TextWrap>
						))}
					</Flex>
				</Panel>
			</Collapse>

			{/* Upload View - Succesfully Uploaded box */}
			<Collapse
				bordered={false}
				expandIconPosition={iconPosition}
				expandIcon={collapseIcon}
				defaultActiveKey={['1']}
				onChange={onChange}
				style={CollapseStyling}
			>
				<Panel
					header={
						<Flex m={3} style={{ alignItems: 'center' }}>
							<SVGICONS.SuccesfullyUploadedIcon />
							<Text style={{ color: '#107C10' }}>Successfully Uploaded</Text>
						</Flex>
					}
					key="1"
				>
					<Box ml={3}>
						{status && (
							<Table
								columns={BrandSalesReturnListColumns}
								dataSource={rowData}
								rowKey={(rowItem) => rowItem.id}
								pagination={false}
							/>
						)}
					</Box>
				</Panel>
			</Collapse>

			{/* Upload View - Failed invoices box */}
			{showFailed && (
				<Collapse
					bordered={false}
					expandIconPosition={iconPosition}
					expandIcon={collapseIcon}
					defaultActiveKey={['1']}
					onChange={onChange}
					style={CollapseStyling}
				>
					<Panel
						m={2}
						header={
							<Flex m={3} style={{ alignItems: 'center' }}>
								<SVGICONS.FailedIcons width={20} />
								<Text style={{ color: '#e11e1e', marginLeft: '25px' }}>
									Failed Invoices
								</Text>
							</Flex>
						}
						key="1"
					>
						<Box>
							<Table
								columns={BrandSalesReturnListColumns}
								dataSource={failedrowData}
								rowKey={(rowItem) => rowItem.id}
								pagination={false}
							/>
						</Box>
					</Panel>
				</Collapse>
			)}
		</>
	);
}
