/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { Col, Modal, Row, message } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { EpodIcon, UserPersmission } from 'utils';
import { UploadEpodModal } from './UploadEpodModal';
import { ACL } from 'components/common';
import UploadEpodButton from 'components/common/UploadEpodButton';
import { Box, Button, Flex } from 'atoms';
import { getImagesList, updateImagesList } from 'store/fulfilment/returnToFCSlice';
import CarouselComponent from 'components/CarouselComponent';
import { GetBrandId } from 'store/masters';
import EpodPhotoVerification from './EpodPhotoVerification';

const StyledFileInfoButton = styled.div`
	color: #494545;
	display: flex;
	align-items: flex-start;
	padding-left: 30px;
`;

const epodTitle = {
	color: '#CA5010',
	fontSize: '15px',
	fontWeight: 500,
};

const approveColor = {
	color: '#5B7F2B',
};

const rejectColor = {
	color: '#FF4747',
};

const PointerWrapper = styled.div`
	width: 24px;
	height: 16px;
`;

const ListStyle = styled.ul`
	list-style: disc;
	margin: 6px 0 6px 0;
	align-content: flex-start;
`;

const FileSizeText = styled(Flex)`
	margin-left: 10px;
	font-style: italic;
	font-size: 14px;
	display: flex;
	align-items: center;
	font-weight: 400;
`;

const NoteText = styled.div`
	text-align: right;
`;

const UploadText = styled.div`
	margin-top: 5px;
	text-align: center;
	color: #fefefe;
	font-size: 13px;
	font-weight: 500;
`;

const StyledImagendSubmit = styled(Flex)`
	justify-content: ${(props) => (props?.fileCount > 4 ? 'center' : 'flex-start')};
	gap: 16px;
	padding: 5px 0;
`;
const FooterBox = styled(Flex)`
	justify-content: space-between;
	flex-direction: column;
`;

const StyledButtons = styled(Button)`
	font-size: 13px;
	padding: 2px 4px;
	border-radius: 4px;
	margin: 0 5px;
	background-color: #5b7f2b;
	${({ disabled }) =>
		disabled &&
		css({
			backgroundColor: 'transparent',
			color: '#87BD87',
			border: '1px solid #87BD87',
			opacity: '0.7 !important',
		})}
`;

const DeleteModal = styled(Modal)`
	.confirm-delete-modal {
		.ant-modal-body {
			text-align: center;
		}
	}
`;

const style = {
	padding: '8px',
};

export default function EpodUploadModal({
	isVerifiyCompleted,
	rfcEditBlock,
	epodUploadModalVisible,
	setEpodUploadModalVisible,
	epodUploadModalData,
	width = '65%',
	epodVerified,
}) {
	const dispatch = useDispatch();
	const uploadFileSize = 15;

	const [imageList, setImageList] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [uploadEpodModal, setUploadEpodModal] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [fileCount, setFileCount] = useState(0);
	const [currentSlider, setCurrentSlider] = useState(null);
	const [brandDetails, setBrandDetails] = useState({});
	const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
	const [getNewImageList, setGetNewImageList] = useState(false);
	const [noOfNotVerifyed, setNoOfNotVerifyed] = useState([]);
	const [showCaution, setShowCaution] = useState(true);

	useEffect(() => {
		setImageList([]);
		dispatch(getImagesList(epodUploadModalData?.id)).then((res) => {
			if (res) {
				setImageList(res);
				setFileCount(res?.length);
				if (res?.length) {
					setCurrentSlider(res[0]);
				}
				setNoOfNotVerifyed(res.filter((data) => !data?.is_verified));
			}
		});

		const brandId = Number(epodUploadModalData?.brand_id);
		if (brandId) {
			dispatch(GetBrandId({ brandId })).then((res) => {
				if (res) {
					setBrandDetails(res);
					setGetNewImageList(false);
				}
			});
		}
	}, [dispatch, uploadEpodModal, getNewImageList]);

	useEffect(() => {
		if (!epodUploadModalData?.bnpl_enabled || epodVerified) {
			setShowCaution(false);
		}
	}, [epodUploadModalData]);

	function updateImageList(res, index = 0) {
		if (res) {
			setFileCount(res?.length);
			setCurrentSlider(res[index]);
			setImageList(res);
			setCurrentSliderIndex(index);
		}
	}

	function DeletImage() {
		const getCurrentIndex = imageList.findIndex((e) => e.image_id === currentSlider?.image_id);
		const showIndex = getCurrentIndex > 0 ? getCurrentIndex - 1 : 0;
		if (currentSlider?.image_id) {
			const imageId = currentSlider?.image_id;
			const imageName = currentSlider?.image_path?.split('/').pop();
			const deleteData = {
				image_id: imageId,
				delete: true,
			};
			dispatch(updateImagesList(deleteData)).then((res) => {
				message.info(`${imageName}  Deleted Successfully`);
				setImageList([]);
				setTimeout(() => {
					updateImageList(res, showIndex);
				}, 10);
			});
		}
	}

	function verifyImage() {
		if (currentSlider?.image_id) {
			const imageId = currentSlider?.image_id;
			const imageName = currentSlider?.image_path?.split('/').pop();
			const verifyData = {
				image_id: imageId,
				is_verified: true,
			};
			dispatch(updateImagesList(verifyData)).then((res) => {
				message.info(`${imageName}  Verified Successfully`);
				setImageList([]);
				const currentSlide = res.findIndex((e) => e.image_id === imageId);
				setTimeout(() => {
					updateImageList(res, currentSlide);
				}, 10);
			});
		}
	}

	// const showModal = () => {
	// 	setIsModalOpen(true);
	// };

	const handleOk = () => {
		// setIsModalOpen(false);
		// setEpodUploadModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleUploadButtonClicked = () => {
		setUploadEpodModal(true);
	};

	const showDeleteModal = () => {
		setDeleteModalVisible(true);
	};

	const handleDeleteConfirm = () => {
		// Add delete functionality here
		DeletImage();
		setDeleteModalVisible(false);
	};

	const handleDeleteCancel = () => {
		setDeleteModalVisible(false);
	};

	return (
		<Modal
			width={width}
			style={{ overflow: 'hidden', position: 'relative', top: '10px' }}
			visible={epodUploadModalVisible}
			onCancel={() => setEpodUploadModalVisible(false)}
			footer={[
				!showCaution && (
					<FooterBox key="footer-content">
						<Row>
							<Col flex="auto">
								<>
									<StyledFileInfoButton>
										{`${fileCount}/5 ${
											fileCount > 1 ? 'Images' : 'Image'
										} Added`}
									</StyledFileInfoButton>
									<ACL allowedGroups={UserPersmission.returnToFC_create}>
										{!rfcEditBlock && (
											<StyledImagendSubmit fileCount={fileCount}>
												{fileCount < 5 &&
													(!epodUploadModalData?.is_invoice_copy_verified ||
														!epodUploadModalData?.return_verified_by ||
														noOfNotVerifyed?.length > 0) &&
													isVerifiyCompleted !== 'CO' && (
														<Flex>
															<UploadEpodButton
																handleUploadModalOpen={
																	handleUploadButtonClicked
																}
															/>
															<FileSizeText>
																Each file must be less than{' '}
																{uploadFileSize} MB.
															</FileSizeText>
														</Flex>
													)}
												{currentSlider &&
													!currentSlider?.is_verified &&
													isVerifiyCompleted !== 'CO' && (
														<div
															key="buttons"
															style={{ textAlign: 'center' }}
														>
															{(fileCount > 1 ||
																epodUploadModalData
																	?.CollectionInvoice?.status ===
																	'No Bill Back') && (
																<StyledButtons
																	className="delete-button"
																	onClick={showDeleteModal}
																	style={{
																		backgroundColor: '#FF4747',
																	}}
																>
																	<DeleteOutlined
																		style={{
																			marginRight: '10px',
																		}}
																	/>
																	Delete
																</StyledButtons>
															)}
															<StyledButtons
																onClick={() => verifyImage()}
															>
																<CheckCircleOutlined
																	style={{
																		marginRight: '10px',
																	}}
																/>
																Verify
															</StyledButtons>
														</div>
													)}

												{/* <StyledButton onClick={showModal}>Submit</StyledButton> */}
											</StyledImagendSubmit>
										)}
									</ACL>
								</>
							</Col>
						</Row>
					</FooterBox>
				),
			]}
		>
			{!epodVerified && epodUploadModalData?.bnpl_enabled && showCaution ? (
				<EpodPhotoVerification
					style={{ padding: '9px 50px' }}
					setShowCaution={setShowCaution}
				/>
			) : (
				<>
					<Col xs={24}>
						<Row gutter={6}>
							<Col xs={14} style={epodTitle}>
								<Flex>
									<PointerWrapper>
										<EpodIcon width={16} />
									</PointerWrapper>
									Proof of Delivery
								</Flex>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Flex>
									<ListStyle>
										<li>
											<span style={approveColor}>Verify </span> Proof of
											Delivery updated by Delivery Agent will be saved.
										</li>
										<li>
											<span style={rejectColor}>Delete </span>Proof of
											Delivery updated by Delivery Agent will be deleted.
										</li>
									</ListStyle>
								</Flex>
							</Col>
							<Col span={12}>
								<NoteText>
									Note: Minimum one Proof of Delivery should be updated.
								</NoteText>
							</Col>
						</Row>
					</Col>
					<Box style={{ minHeight: '340px', backgroundColor: '#e8e8e8' }}>
						<div style={{ color: '#494545', fontWeight: 'bold' }}>
							<Row gutter={16}>
								<Col className="gutter-row" span={6}>
									<div style={style}>
										Invoice No: <div>{epodUploadModalData?.invoice_no}</div>
									</div>
								</Col>
								<Col className="gutter-row" span={6}>
									<div style={style}>
										Store Name:
										<div>{epodUploadModalData?.Store?.name}</div>
									</div>
								</Col>
								<Col className="gutter-row" span={6}>
									<div style={style}>
										Brand:
										<div>{brandDetails?.name}</div>
									</div>
								</Col>
								<Col className="gutter-row" span={6}>
									<div style={style}>
										Invoice Value:
										<div>
											{epodUploadModalData?.CollectionInvoice?.invoice_amount}
										</div>
									</div>
								</Col>
							</Row>
							{/* <p style={{ color: '#ffffff' }}>{currentSlider?.image_id}</p> */}
						</div>
						{imageList.length > 0 && currentSlider ? (
							<CarouselComponent
								currentSliderNo={currentSliderIndex}
								setCurrentSlider={setCurrentSlider}
								imageList={imageList}
							/>
						) : (
							<>
								<UploadText style={{ paddingTop: '100px', color: '#000000' }}>
									Proof Of Delivery is Not Available!
								</UploadText>
								<UploadText style={{ fontSize: '10px', color: '#000000' }}>
									Please Upload Proof Of Delivery to continue verifying Delivery.
								</UploadText>
							</>
						)}
					</Box>
				</>
			)}
			<Modal
				centered
				visible={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				okText="Yes"
				cancelText="No"
				closable={false}
				width={270}
			>
				Are you sure you have verified proof of delivery properly?
			</Modal>
			<DeleteModal
				centered
				visible={deleteModalVisible}
				onOk={handleDeleteConfirm}
				onCancel={handleDeleteCancel}
				okText="Yes"
				cancelText="No"
				closable={false}
				width={270}
				className="confirm-delete-modal"
			>
				Are you sure you want to delete this proof of delivery?
			</DeleteModal>
			{uploadEpodModal && (
				<UploadEpodModal
					epodUploadModalData={epodUploadModalData}
					toggleState={[uploadEpodModal, setUploadEpodModal]}
					uploadFileSize={uploadFileSize}
				/>
			)}
		</Modal>
	);
}

EpodUploadModal.propTypes = {
	isVerifiyCompleted: PropTypes.any,
	rfcEditBlock: PropTypes.any,
	epodUploadModalVisible: PropTypes.bool,
	setEpodUploadModalVisible: PropTypes.func,
	epodUploadModalData: PropTypes.any,
	width: PropTypes.number,
	epodVerified: PropTypes.any,
};
