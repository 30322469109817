import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EpodIcon, WarningIcon } from 'utils';
import { Button, Flex } from 'atoms';
import EpodAlertImage from '../../assets/PODAlertImage.jpg';

const PointerWrapper = styled(Flex)`
	width: 24px;
`;

const FooterBox = styled(Flex)`
	justify-content: center;
	margin: 0;
	padding: 0;
	position: relative;
`;

const StyledWrapper = styled.div`
	background-color: #e8e8e8;
	margin: 20px 5px 0;
	padding: 5px;
`;

const StyledImage = styled.img`
	width: 100%;
`;

export default function EpodPhotoVerification({ width = '100%', setShowCaution }) {
	return (
		<StyledWrapper style={{ width }}>
			<Flex
				align="center"
				justifyContent="center"
				style={{ margin: '5px 10px', fontSize: '17px', fontWeight: 'bold' }}
			>
				<PointerWrapper>
					<EpodIcon width={16} />
				</PointerWrapper>
				<span>Proof of Delivery</span>
			</Flex>

			<Flex
				justifyContent="center"
				style={{
					margin: '5px 10px',
					fontWeight: 'bold',
					fontSize: '17px',
					color: '#ff4747',
				}}
			>
				<WarningIcon />
				<span>
					You are verifying proof of delivery for QUID, and it will be sent to QUID.
					Please be careful.
				</span>
			</Flex>

			<div>
				<StyledImage src={EpodAlertImage} alt="Accepted" />
			</div>
			<FooterBox key="footer-content">
				<Button onClick={() => setShowCaution(false)}>Continue</Button>
			</FooterBox>
		</StyledWrapper>
	);
}

EpodPhotoVerification.propTypes = {
	width: PropTypes.string,
	setShowCaution: PropTypes.any,
};
