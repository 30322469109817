/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal, message } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ACL, SearchBar } from 'components/common';
import { Box, Flex, Button, Text } from 'atoms';
import {
	getReturnToFCShow,
	// updateOrderInvoiceStatus,
	updateVerifyOrder,
	allocationComplete,
	storeInvoiceId,
	storeOrderDetails,
	rfcBlockerStatus,
	postCrateDetails,
	getCrateDetails,
	getGEOFenceStatus,
	getImagesList,
} from 'store/fulfilment/returnToFCSlice';
import {
	LocationNotAvailableIcons,
	NewLocationIcons,
	OutOfLocationIcons,
	RTFInvoiceListTableConfig,
	StoireLocationIcons,
	UserPersmission,
} from 'utils';
import { RTFInvoiceSummary } from './RTCInvoiceSummary';
import { UploadInvoiceModal } from 'components/modals/UploadInvoiceModal';
import { ShowUploadListModal } from 'components/modals/ShowUploadListModal';
import UploadInvoiceButton from 'components/common/UploadInvoiceButton';
import VerifyCompleteAlert from 'components/modals/VerifyCompleteAlert';
import CrateTracking from '../CrateTracking';
import { EpodUploadModal, RFCLocationModal } from 'components/modals';
import { retailerKYC } from 'store/fulfilment/salesOrderSlice';
import * as SVGICONS from 'utils/Svg';
import EpodPhotoVerification from 'components/modals/EpodPhotoVerification';

const initialValues = { visible: false, updateData: null, type: null };

const rolesAllowedToUpload = ['Transport Manager', 'System Admin'];

const StyledFileInfoButton = styled(Button)`
	background-color: transparent;
	border: none;
	color: #ca5010;
	text-decoration: underline;
`;

const MapInfoContainer = styled.div`
	background: #eeeeee;
	max-width: 650px;
	margin: -48px 0 0 16px;
	position: relative;
	padding: 10px;
	font-size: 12px;
	display: flex;
	gap: 20px;
`;

const MapIconContainer = styled.div`
	display: flex;
	align-items: center;
	div {
		width: 18px;
	}
`;

const InfoWrapper = styled.div`
	background: #e0f3ff;
	width: 100%;
	padding: 10px;
	justify-content: center;
	display: flex;
	align-items: center;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function RTFInvoiceList() {
	const { id } = useParams();
	const locationRoutes = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const { auth } = useSelector((state) => state?.auth);
	const ERR_MESSAGE = 'Order should not be Vehicle Allocated or Out for delivery';
	const searchParams = new URLSearchParams(locationRoutes.search);
	const quid = searchParams.get('quid');
	const defaultSearchVal = searchParams.get('filter') ?? '';
	const [totalData, setTotalData] = useState();
	const [rowData, setRowData] = useState([]);
	const [modalVisible, setModalVisible] = useState(initialValues);
	const [uploadInvoiceModalOpen, setUploadInvoiceModalOpen] = useState(false);
	const [rfcEditBlock, setRfcEditBlock] = useState(false);
	const [invoiceListModalOpen, setInvoiceListModalOpen] = useState(false);
	const [daysLeft, setDaysLeft] = useState(0);
	const [totalFiles, setTotalFiles] = useState(0);
	const [filesUploaded, setFilesUploaded] = useState(0);
	const [verifyCompleteAlert, setVerifyCompleteAlert] = useState(false);
	const [statusExist, setStatusExist] = useState({});
	const [listUpdated, setListUpdated] = useState(false);
	const [fileCount, setFileCount] = useState(0);
	const [crateData, setCrateData] = useState();
	const [currentOrderId, setCurrentOrderId] = useState(null);
	const [epodEnabled, setEpodEnabled] = useState(true);
	const [crateFieldValues, setCrateFieldValues] = useState({
		received_crate_count: '',
		reason: '',
		error: false,
		edit: false,
	});
	const [locationModalVisible, setLocationModalVisible] = useState({
		showModal: false,
		locationType: null,
		latLong: null,
		storeName: null,
	});
	const [epodUploadModalVisible, setEpodUploadModalVisible] = useState(false);
	const [epodPhotoVerificationVisible, setEpodPhotoVerificationVisible] = useState(false);
	const [epodUploadModalData, setEpodUploadModalData] = useState(false);
	const [isVerifiyCompleted, setIsVerifiyCompleted] = useState(false);
	const [currentInvoiceStatus, setCurrentInvoiceStatus] = useState(null);
	const [searchVal, setSearchVal] = useState(defaultSearchVal);
	const [epodVerified, setEpodVerified] = useState(false);

	const allowedUser = auth?.user?.groups[0].name;
	const uploadAllowed = rolesAllowedToUpload?.includes(auth?.user.groups[0]?.name);

	function fetchData() {
		dispatch(getReturnToFCShow(id)).then((res) => {
			setRowData(
				res?.Orders?.map((x) => ({
					...x,
					bill_status: x?.CollectionInvoice?.status,
					return_status: res?.return_status,
				})) || []
			);
			setTotalData(res);
			// console.log('setIsVerifiyCompleted res ', res);
			if (res?.status) {
				setIsVerifiyCompleted(res?.status);
			}
			setListUpdated(false);
			if (searchVal) {
				handleClientSearch(searchVal, res);
			}
		});
	}

	useEffect(() => {
		const fenceData = {
			store_id: totalData?.Orders[0]?.Store?.id,
			brand_id: totalData?.Orders[0]?.brand_id,
			fc_id: totalData?.fc_id,
		};

		setIsSuperAdmin(auth?.user?.is_superuser);

		if (fenceData?.store_id) {
			dispatch(getGEOFenceStatus(fenceData)).then((res) => {
				if (res?.data && UserPersmission.returnToFC_create.includes(allowedUser)) {
					setEpodEnabled(res?.data?.epod_enabled);
				}
			});
		}
	}, [totalData]);

	useEffect(() => {
		dispatch(storeInvoiceId(id));
		fetchData();
	}, [dispatch, id, filesUploaded, listUpdated]);

	useEffect(() => {
		dispatch(getCrateDetails(id)).then((res) => {
			setCrateData(res);
			setCrateFieldValues({
				received_crate_count: res?.crate_log?.received_crate,
				reason: res?.crate_log?.mismatch_reason,
			});
		});
	}, [dispatch, id]);

	const hangleVerify = () => {
		fetchData();
	};

	useEffect(() => {
		if (!isEmpty(totalData?.Orders)) {
			dispatch(rfcBlockerStatus(totalData?.Orders[0]?.CollectionInvoice?.order_id)).then(
				(res) => {
					setRfcEditBlock(res?.rfc_edit_block);
				}
			);
		}
	}, [dispatch, totalData?.Orders]);

	useEffect(() => {
		if (!epodUploadModalVisible) {
			fetchData();
		}
	}, [epodUploadModalVisible]);

	const handleStatus = (rec, status, type, delStatus) => {
		dispatch(storeOrderDetails({ new_orderStatus: status, ...rec }));
		setModalVisible({
			visible: true,
			type,
			updateData: {
				orderId: rec?.id,
				status: type === 'invoice' ? status : null,
				order_status: type === 'delivery' ? status : delStatus,
			},
		});
	};

	const rolesAllowed = ['Transport Manager', 'System Admin'];

	const handleUpdateOrderInoviceStatus = () => {
		const {
			updateData: { orderId },
			type,
		} = modalVisible;

		if (type === 'delivery') {
			return navigate(
				`/logistics-management/return-to-fc-new/${orderId}/delivery-details?filter=${searchVal}`
			);
		}
		return navigate(
			`/logistics-management/return-to-fc-new/${orderId}/collection?filter=${searchVal}`
		);

		// this code will not be reached
		// return dispatch(updateOrderInvoiceStatus({ status, order_status }, orderId))
		//  .then(() => {
		//      setRowData((prev) =>
		//          prev.map((x) => {
		//              if (x.id === orderId) {
		//                  const updateObj =
		//                      type === 'invoice'
		//                          ? { bill_status: status }
		//                          : { status: order_status };
		//                  return { ...x, ...updateObj };
		//              }
		//              return x;
		//          })OD
		//      );
		//      setModalVisible(initialValues);
		//      message.info('Status updated Successfully');
		//  })
		//  .catch((e) => {
		//      setModalVisible(initialValues);
		//      if ('response' in e) {
		//          const gotoURL = type === 'invoice' ? 'collection' : 'delivery-details';
		//          navigate(`/logistics-management/return-to-fc-new/${orderId}/${gotoURL}`);
		//      }
		//  });
	};

	const allowedGrps = rolesAllowed?.includes(auth?.user.groups[0].name);

	const handleClientSearch = (val, updatedTotalData) => {
		setSearchVal(val);
		navigate(`?quid=${quid}&filter=${val}`);
		const data = updatedTotalData ?? totalData;
		if (data) {
			setRowData(
				data?.Orders?.map((x) => ({
					...x,
					bill_status: x?.CollectionInvoice?.bill_status,
				})).filter((x) => x?.invoice_no?.includes(val) || x?.Store?.name?.includes(val))
			);
		}
	};

	// Handle Vefify Order
	const handleVerifyOrder = (orderId, val) => {
		// console.log('handleVerifyOrder: ', orderId, val);
		dispatch(updateVerifyOrder({ verify: val ? 0 : 1 }, orderId)).then(() => {
			message.info(`Order ${val ? 'unverified' : 'verified'} successfully`);
			const updatedRowData = totalData?.Orders?.map((x) =>
				x.id === orderId ? { ...x, return_verified_by: !x?.return_verified_by } : x
			);
			const updatedTotalData = { ...totalData, Orders: updatedRowData };
			setTotalData(updatedTotalData);
			if (searchVal) {
				handleClientSearch(searchVal, updatedTotalData);
			} else {
				setRowData(updatedRowData);
			}
		});
	};

	// EPOD Get List of not Active images
	// const getNotActiveImagesList = (res) =>
	// 	// console.log('getNotActiveImagesList: ', res);

	const checkApprovalStatus = (res) =>
		res?.data?.approval_status !== 'approved' || res?.data?.approval_status !== 'rejected';
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const handleLocation = async (record, location, Actiontype = null, NoBillBack = false) => {
		// console.log('handleLocation: ', record, location, Actiontype, NoBillBack);
		let NotVerified;
		let Verified;
		let TotalImages;

		// Set current invoice status
		setCurrentInvoiceStatus(record?.status);

		await dispatch(getImagesList(record?.id)).then((data) => {
			NotVerified = data.filter((r) => !r?.is_verified);
			Verified = data.filter((r) => r?.is_verified);
			TotalImages = data.length;
			const orderIdNo = record?.id;
			const handleRes = record;
			setCurrentOrderId(orderIdNo);
			console.log('getImagesList ', NotVerified, Verified, TotalImages);

			dispatch(retailerKYC(orderIdNo)).then((res) => {
				// console.log('res kyc ', res);
				// console.log('setCurrentOrderId: ', record, handleRes, Actiontype, orderIdNo);
				if (
					rfcEditBlock ||
					!uploadAllowed ||
					isVerifiyCompleted === 'CO' ||
					record?.tag.toLowerCase() === 'eco'
				) {
					// console.log('sample smaple ', rfcEditBlock, uploadAllowed, location);
					showLocationModal(record, location);
				} else if (Actiontype) {
					// console.log('came inside the actions res ', res, location, NoBillBack, record);
					if (NoBillBack) {
						// console.log('came inside no billback');
						if (
							(checkApprovalStatus(res) &&
								res?.data?.approval_status !== null &&
								res?.data?.store_latitude &&
								res?.data?.store_longitude) ||
							res?.data?.approval_status === 'pending'
						) {
							// console.log('came inside no conditions');
							showLocationModal(record, location);
						} else if (Verified?.length === TotalImages) {
							// console.log('came inside no storeLocation');
							showLocationModal(record, 'storeLocation');
						} else {
							// console.log('openEpodModal');
							openEpodModal(record);
						}
					} else if (
						res?.data?.approval_status === 'approved' ||
						res?.data?.approval_status === 'rejected'
					) {
						// console.log('came inside no handleLocationActions');
						handleLocationActions(record, res, Actiontype, handleRes);
					} else if (
						!record?.is_invoice_copy_verified &&
						!['CA', 'DA'].includes(record?.status) &&
						res?.data?.approval_status !== 'pending'
					) {
						// console.log('came inside no open epod modal');
						handleLocationActions(record, res, Actiontype, handleRes);
					} else {
						console.log('came inside no showLocationModal');
						showLocationModal(record, location);
					}
				}
				//  else if (epodEnabled && NotVerified.length > 0 && TotalImages > 0) {
				// 	openEpodModal(record);
				// }
				else {
					// console.log('came else showLocationModal');
					const locationType =
						res?.data?.approval_status === 'approved' ||
						res?.data?.approval_status === 'rejected'
							? 'storeLocation'
							: location;
					showLocationModal(record, locationType);
				}
			});
		});
	};

	// Handle location action
	const handleLocationActions = async (rec, res, Actiontype, handleRes) => {
		console.log('handleLocationActions: ', rec, res, Actiontype, handleRes);
		switch (Actiontype) {
			case 'handleViewOrders':
				handleViewOrders(handleRes);
				break;
			case 'handleCollection':
				handleCollection(handleRes);
				break;
			case 'handleVerifyButton':
				handleVerifyButton(handleRes);
				break;
			case 'handleEPODUpload':
				handleEpodUpload(handleRes, Actiontype);
				break;
			default:
				if (!rec?.Store?.latitude) {
					rec = {
						...rec,
						...(rec.Store.latitude = res?.data?.latitude),
						...(rec.Store.longitude = res?.data?.longitude),
					};
				}
				showLocationModal(rec, res?.data?.approval_status);
				break;
		}
	};

	// Final show location modal
	const showLocationModal = (res, location) => {
		console.log('showLocationModal: ', res, location);
		setLocationModalVisible({
			showModal: true,
			locationType: location,
			latLong: { lat: res?.Store?.latitude, long: res?.Store?.longitude },
			storeName: res?.Store?.name,
		});
	};

	// EPOD Get List of not Active images
	const getNotActiveImagesList = async (res) => {
		// console.log('getNotActiveImagesList: ', res);
		const ImageLists = await dispatch(getImagesList(res?.id));
		const NotVerified = ImageLists.filter((r) => !r?.is_verified);
		const Verified = ImageLists.filter((r) => r?.is_verified);
		const TotalImages = ImageLists.length;
		return { NotVerified, Verified, TotalImages };
	};

	// console.log(ImageLists, 'ImageLists');
	//
	// Handle Final actions call
	const handleFinalActions = async (res, name) => {
		// console.log('handleFinalActions: ', res, name);
		const isAllImagesVerifyed = res?.is_invoice_copy_verified;
		if (name === 'handleViewOrders') {
			// eslint-disable-next-line no-unused-expressions
			isAllImagesVerifyed ? finalAction(res, 'handleViewOrders') : openEpodModal(res);
		} else if (name === 'handleCollection') {
			// eslint-disable-next-line no-unused-expressions
			isAllImagesVerifyed ? finalAction(res, 'handleCollection') : openEpodModal(res);
		} else if (name === 'handleVerifyButton') {
			// eslint-disable-next-line no-unused-expressions
			isAllImagesVerifyed ? finalAction(res, 'handleVerifyButton') : openEpodModal(res);
		} else if (name === 'handleEPODUpload') {
			// eslint-disable-next-line no-unused-expressions
			openEpodModal(res);
		}
	};

	// Handle "No Bill Back"
	const handleNoBillBack = async (res, actionType) => {
		// console.log('handleNoBillBack: ', res, actionType);
		const notActiveImagesList = await getNotActiveImagesList(res);
		if (notActiveImagesList?.Verified.length === notActiveImagesList.TotalImages) {
			finalAction(res, actionType);
		} else if (notActiveImagesList?.NotVerified.length > 0) {
			openEpodModal(res);
		} else {
			handleFinalActions(res, actionType);
		}
	};

	// Handle Epod Validation
	const handleEpodValidation = async (res, actionType) => {
		// console.log('handleEpodValidation: ', res, actionType);
		if (
			res?.CollectionInvoice?.status === 'No Bill Back' &&
			['DL', 'PD'].includes(res?.status) &&
			!rfcEditBlock
		) {
			handleNoBillBack(res, actionType);
		} else {
			handleFinalActions(res, actionType);
		}
	};

	// Handle final action
	// eslint-disable-next-line consistent-return
	const finalAction = (res, type) => {
		// console.log('finalAction: ', res, type);
		if (type === 'handleViewOrders') {
			redirectDetailPage(res?.id);
		} else if (type === 'handleCollection') {
			redirectCollectionPage(res);
		} else if (type === 'handleVerifyButton') {
			handleVerifyToggle(res);
		} else if (type === 'handleEPODUpload') {
			openEpodModal(res);
		}
	};

	// Redirect detail page
	const redirectDetailPage = (detailId) => {
		// console.log('redirectDetailPage: ', detailId);
		dispatch(storeOrderDetails({}));
		navigate(
			`/logistics-management/return-to-fc-new/${detailId}/delivery-details?quid=${quid}&filter=${searchVal}`
		);
	};

	// Redirect collection page
	const redirectCollectionPage = (res) => {
		// console.log('redirectCollectionPage: ', res);
		const hasDeliveryStatus = ['VA', 'OD', 'XD'].includes(res?.status);
		if (hasDeliveryStatus) return message.info(ERR_MESSAGE);
		if (!['DL', 'PD'].includes(res?.status))
			return message.info('Only delivered and partail delivery order can view collections');
		return navigate(
			`/logistics-management/return-to-fc-new/${res?.id}/collection?quid=${quid}&filter=${searchVal}`
		);
	};

	// Verify toggle
	const handleVerifyToggle = (res) => {
		// console.log('handleVerifyToggle: ', res);
		const hasDeliveryStatus = ['VA', 'OD', 'XD'].includes(res?.status);
		const invoiceStatus = res?.CollectionInvoice?.status === 'Pending';
		if (!uploadAllowed) return message.info('Billing executives are not allowed to verify');
		if (hasDeliveryStatus) return message.info(ERR_MESSAGE);
		if (invoiceStatus) return message.info('Order invoice should not be in pending');

		if (res?.return_status !== 'Complete') {
			return handleVerifyOrder(res?.id, res?.return_verified_by);
		}
		return message.info('Order has been completed');
	};

	// Open Epod Modal
	const openEpodModal = (res) => {
		// console.log('openEpodModal: ', res);
		setEpodUploadModalData(res);
		setEpodUploadModalVisible(true);
	};

	const openPhotoVerificationModal = (res) => {
		setEpodUploadModalData(res);
		setEpodPhotoVerificationVisible(true);
	};

	// Handle Epod Upload
	const handleEpodUpload = (res, Actiontype) => {
		setEpodVerified(res?.is_invoice_copy_verified);
		// console.log('handleEpodUpload: ', res, Actiontype);
		if (
			['CA', 'DA', 'VA', 'OD', 'XD'].includes(res?.status) ||
			!epodEnabled ||
			!uploadAllowed
		) {
			// console.log('[CA, DA].includes(res?.status');
			finalAction(res, Actiontype);
		} else if (epodEnabled && !res?.is_invoice_copy_verified && uploadAllowed) {
			// console.log('epodEnabled && !res?.is_invoice_copy_verified');
			handleEpodValidation(res, Actiontype);
		} else {
			// console.log('openEpodModal');
			openEpodModal(res);
		}
	};

	const handlePhotoVerification = (res) => {
		openPhotoVerificationModal(res);
	};

	// Handle View Orders
	const handleViewOrders = (res) => {
		// console.log('handleViewOrders: ', res);
		if (!uploadAllowed || isVerifiyCompleted === 'CO' || res?.tag.toLowerCase() === 'eco') {
			finalAction(res, 'handleViewOrders');
		} else if (
			epodEnabled &&
			!res?.is_invoice_copy_verified &&
			!['CA', 'DA'].includes(res?.status) &&
			!rfcEditBlock
		) {
			handleEpodValidation(res, 'handleViewOrders');
		} else {
			finalAction(res, 'handleViewOrders');
		}
	};

	// const handleStartVerification = () => {};
	const handleCollection = (res) => {
		// console.log('handleCollection: ', res);
		if (!uploadAllowed || isVerifiyCompleted === 'CO' || res?.tag.toLowerCase() === 'eco') {
			finalAction(res, 'handleCollection');
		} else if (
			epodEnabled &&
			!res?.is_invoice_copy_verified &&
			!['CA', 'DA'].includes(res?.status) &&
			!rfcEditBlock
		) {
			handleEpodValidation(res, 'handleCollection');
		} else {
			finalAction(res, 'handleCollection');
		}
	};

	// Habdle verify button
	const handleVerifyButton = (res) => {
		// console.log('handleVerifyButton:', res);
		if (!uploadAllowed || isVerifiyCompleted === 'CO' || res?.tag.toLowerCase() === 'eco') {
			finalAction(res, 'handleVerifyButton');
		} else if (
			epodEnabled &&
			!res?.is_invoice_copy_verified &&
			!['CA', 'DA'].includes(res?.status) &&
			!rfcEditBlock
		) {
			handleEpodValidation(res, 'handleVerifyButton');
		} else {
			finalAction(res, 'handleVerifyButton');
		}
	};

	const handleUploadModalOpen = () => {
		setUploadInvoiceModalOpen(true);
	};

	const showUploadList = () => {
		setInvoiceListModalOpen(true);
	};

	const getElapsedTime = (rfcdate) => {
		// console.log('getElapsedTime: ', rfcdate);
		let diff;
		if (['System Admin'].includes(allowedUser)) {
			diff = 90 - moment().diff(moment(rfcdate), 'days');
		} else if (['Transport Manager'].includes(allowedUser)) {
			diff = 3 - moment().diff(moment(rfcdate), 'days');
		}
		if (diff > 0) {
			setDaysLeft(diff);
		} else {
			setDaysLeft(0);
		}
	};

	const handleCompleteOrder = () => {
		if (crateData?.crate_log?.dispatched_crate && !crateFieldValues?.received_crate_count) {
			message.info('Received crate count field cannot be empty');
		} else if (
			crateData?.crate_log?.dispatched_crate &&
			crateFieldValues?.received_crate_count > crateData?.crate_log?.dispatched_crate
		) {
			message.info('Received crate count cannot be greater than Dispatched crate count');
		} else if (
			crateData?.crate_log?.dispatched_crate &&
			crateFieldValues?.received_crate_count < crateData?.crate_log?.dispatched_crate &&
			!crateFieldValues?.reason
		) {
			message.info('Reason cannot be empty');
		} else if (
			totalFiles < 1 &&
			statusExist.invoiceReturnedVoucher &&
			statusExist.showUpload &&
			uploadAllowed
		) {
			setVerifyCompleteAlert(true);
		} else {
			if (crateData?.crate_log?.dispatched_crate > 0 && crateFieldValues?.edit) {
				dispatch(postCrateDetails(id, crateFieldValues)).then(() => {
					setCrateFieldValues({ ...crateFieldValues, edit: false });
					// console.log('success', res);
				});
			}
			dispatch(allocationComplete({}, id, {}, 'put')).then(() => {
				setTotalData((prev) => ({ ...prev, return_status: 'Complete' }));
				setRowData((prev) => prev.map((x) => ({ ...x, return_status: 'Complete' })));
				message.info('Return to FC Completed');
				dispatch(getReturnToFCShow(id)).then((res) => {
					setTotalData(res);
				});
			});
		}
	};

	useEffect(() => {
		if (totalData?.return_process_completed_at) {
			getElapsedTime(totalData?.return_process_completed_at);
		}
	}, [totalData]);

	useEffect(() => {
		setTotalFiles(totalData?.total_files_uploaded);
	}, [totalData]);

	const isVerified = totalData?.return_status === 'Complete';
	// const rfcBlock = totalData?.Orders[0]?.FcBrand?.block_rfc;
	// const statusincludes = ['VA', 'OD'].includes(totalData?.status);

	const checkStatus = () => {
		totalData?.Orders?.forEach((order) => {
			if (order.status === 'DL' || order.status === 'PD') {
				if (
					order.CollectionInvoice.status === 'Bill Back' &&
					(order.CollectionInvoice.billtype === 'Bill' ||
						order.CollectionInvoice.billtype === 'Voucher')
				) {
					setStatusExist((prev) => ({
						...prev,
						invoiceReturnedVoucher: true,
						showUpload: true,
					}));
				} else if (
					order.CollectionInvoice.status === 'No Bill Back' &&
					order.CollectionInvoice.billtype === 'Bill'
				) {
					setStatusExist((prev) => ({
						...prev,
						showUpload: true,
					}));
				} else {
					setStatusExist((prev) => ({
						...prev,
						invoiceReturnedVoucher: false,
						showUpload: false,
					}));
				}
			}
		});
	};

	useEffect(() => {
		checkStatus();
	}, [totalData]);

	const buttonDisabled = useMemo(
		() =>
			!statusExist?.showUpload ||
			isEmpty(totalData?.Orders) ||
			!totalData?.Orders?.every((x) => x?.return_verified_by),
		[statusExist, totalData]
	);

	const buttonHide = useMemo(
		() => !uploadAllowed || (isVerified && !daysLeft) || (isVerified && buttonDisabled),
		[uploadAllowed, isVerified, daysLeft, buttonDisabled]
	);

	useEffect(() => {
		if (totalData?.file_download_link?.length) {
			const filesNotDeleted = totalData?.file_download_link.filter((file) => file.is_active);
			setFileCount(filesNotDeleted.length);
		}
	}, [totalData, listUpdated]);

	return (
		<Box mt={3}>
			<RTFInvoiceSummary data={totalData} />
			{quid > 0 ? (
				<InfoWrapper>
					<Text>
						<InfoCircleOutlined style={{ marginRight: '5px' }} />
						There are few quid orders.Please be careful while verifying these orders,
						Once verified, these orders cannot be reopened for RFC.
					</Text>
					<span>
						<SVGICONS.QuidSalesOrderIcons style={{ display: 'inline-flex' }} />
					</span>
				</InfoWrapper>
			) : null}
			<Flex
				justifyContent="space-between"
				margin="1.5rem 0 1rem 0"
				paddingBottom="1.2rem"
				alignItems="baseline"
				borderBottom="1px solid #E2E2E2"
			>
				<CrateTracking
					isVerified={isVerified}
					crateFieldValues={crateFieldValues}
					setCrateFieldValues={setCrateFieldValues}
					crateData={crateData}
				/>
				<Flex>
					{/* <ACL allowedGroups={UserPersmission.returnToFC_create}>
                        <Button onClick={handleStartVerification}>Start Verification</Button>
                    </ACL> */}
					{totalData?.file_download_link?.length > 0 && (
						<StyledFileInfoButton onClick={showUploadList}>
							{`(${fileCount} ${fileCount > 1 ? 'files' : 'file'} uploaded)`}
						</StyledFileInfoButton>
					)}
					{!buttonHide && (
						<Box style={{ marginRight: '28px' }} position="relative">
							{daysLeft ? (
								<Text
									mb="10px"
									fontSize="12px"
									fontWeight="400"
									color="#6A6A6A"
									fontStyle="italic"
									position="absolute"
									bottom="40px"
									left="0"
									right="0"
									textAlign="center"
								>
									{`${daysLeft} days left to upload Document`}
								</Text>
							) : null}
							<ACL allowedGroups={UserPersmission.returnToFC_create}>
								<UploadInvoiceButton
									handleUploadModalOpen={handleUploadModalOpen}
									disabled={buttonDisabled}
								/>
							</ACL>
						</Box>
					)}
					<ACL allowedGroups={UserPersmission.returnToFC_create}>
						<Button
							disabled={
								isEmpty(totalData?.Orders) ||
								!uploadAllowed ||
								!totalData?.Orders?.every((x) => x?.return_verified_by) ||
								isVerified
							}
							onClick={handleCompleteOrder}
						>
							{isVerified ? 'Verified' : 'Verify'}
						</Button>
					</ACL>
				</Flex>
			</Flex>
			<SearchBar
				onSearch={handleClientSearch}
				style={{ width: '300px', marginBottom: '20px' }}
				placeholder="Search Invoice number / Retailer name"
				queryValue={searchVal}
			/>

			{allowedGrps && rfcEditBlock > 0 && (
				<Box
					style={{
						background: '#CA5010',
						color: '#fff',
						padding: '16px',
						textAlign: 'center',
						marginBottom: '16px',
						borderRadius: '4px',
					}}
				>
					You cannot Edit or Update the details until Delivery Person completes Delivery
					activity in Mobile Application.
				</Box>
			)}
			<Table
				rowKey="id"
				columns={RTFInvoiceListTableConfig({
					isVerifiyCompleted,
					handleStatus,
					// navigate,
					// handleVerifyOrder,
					handleViewOrders,
					uploadAllowed,
					rfcEditBlock,
					handleCollection,
					crateFieldValues,
					handleLocation,
					handleEpodUpload,
					handlePhotoVerification,
					epodEnabled,
					isSuperAdmin,
					handleVerifyButton,
				})}
				dataSource={rowData || []}
			/>
			{modalVisible.visible && (
				<Modal
					title={`Update ${
						modalVisible?.type === 'invoice' ? 'Invoice' : 'Delivery'
					} Status`}
					visible={modalVisible.visible}
					onCancel={() => setModalVisible(initialValues)}
					onOk={handleUpdateOrderInoviceStatus}
				>
					<Box textAlign="center">
						Are you sure want to change
						{modalVisible?.type === 'invoice' ? ' Invoice' : ' Delivery'} status?
					</Box>
				</Modal>
			)}
			{uploadInvoiceModalOpen && (
				<UploadInvoiceModal
					toggleState={[uploadInvoiceModalOpen, setUploadInvoiceModalOpen]}
					rowData={totalData || {}}
					daysLeft={daysLeft}
					auth={auth}
					setFilesUploaded={setFilesUploaded}
					fileCount={fileCount}
					crateFieldValues={crateFieldValues}
					crateData={crateData}
					setCrateFieldValues={setCrateFieldValues}
					acceptonlyPDF
				/>
			)}
			{invoiceListModalOpen && (
				<ShowUploadListModal
					toggleState={[invoiceListModalOpen, setInvoiceListModalOpen]}
					showHeader={uploadAllowed}
					handleUploadModalOpen={handleUploadModalOpen}
					daysLeft={daysLeft}
					totalData={totalData}
					setTotalData={setTotalData}
					buttonHide={buttonHide}
					buttonDisabled={buttonDisabled}
					isVerified={isVerified}
					statusExist={statusExist}
					setListUpdated={setListUpdated}
				/>
			)}
			{verifyCompleteAlert && (
				<VerifyCompleteAlert
					toggleState={[verifyCompleteAlert, setVerifyCompleteAlert]}
					handleUploadModalOpen={handleUploadModalOpen}
				/>
			)}
			{locationModalVisible?.showModal && (
				<RFCLocationModal
					currentInvoiceStatus={currentInvoiceStatus}
					isVerifiyCompleted={isVerifiyCompleted}
					uploadAllowed={uploadAllowed}
					rfcEditBlock={rfcEditBlock}
					invoiceId={+id}
					currentOrderId={currentOrderId}
					locationModalVisible={locationModalVisible}
					hangleVerify={hangleVerify}
					setLocationModalVisible={setLocationModalVisible}
				/>
			)}
			{rowData.length > 0 && (
				<MapInfoContainer>
					<MapIconContainer>
						<div>
							<StoireLocationIcons width={12} />
						</div>
						Delivered in Store Loc
					</MapIconContainer>
					<MapIconContainer>
						<div>
							<NewLocationIcons width={12} />
						</div>
						Delivered in New Loc
					</MapIconContainer>
					<MapIconContainer>
						<div>
							<OutOfLocationIcons width={12} />
						</div>
						Delivered Out Of Store Loc
					</MapIconContainer>
					<MapIconContainer>
						<div>
							<LocationNotAvailableIcons width={12} />
						</div>
						Location Not Available
					</MapIconContainer>
				</MapInfoContainer>
			)}

			{epodUploadModalVisible && (
				<EpodUploadModal
					isVerifiyCompleted={isVerifiyCompleted}
					rfcEditBlock={rfcEditBlock}
					epodUploadModalData={epodUploadModalData}
					epodUploadModalVisible={epodUploadModalVisible}
					setEpodUploadModalVisible={setEpodUploadModalVisible}
					epodVerified={epodVerified}
				/>
			)}

			{epodPhotoVerificationVisible && (
				<EpodPhotoVerification
					epodPhotoVerificationVisible={epodPhotoVerificationVisible}
					setEpodPhotoVerificationVisible={setEpodPhotoVerificationVisible}
				/>
			)}
		</Box>
	);
}
