/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { isEmpty } from 'lodash';
import { DownloadOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons';
import { Col, Tooltip } from 'antd';
import { theme } from 'styles/theme';
import eyeIcon from 'assets/icons/eye-icon.svg';
import { Text, Box, Flex, Image } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import DateFormat from 'components/DateFormat';
import { ADAPTER_UPLOAD_FILE_TYPE_CHOICE } from 'utils';
import {
	FILE_STATUS_CHOICE,
	hasErrorTypesRefresh,
	hasDownloadErrorTypes,
	srHideEyeTypes,
} from 'utils/tableConfig/OrderAdapteTablController';
import { retryStockOneAPI } from 'store/UploadSlice';
// const Pusher = require('pusher-js');
// eslint-disable-next-line no-useless-concat
const s3Bucket = `https://` + `${process.env?.REACT_APP_BUCKET_NAME}.s3.ap-south-1.amazonaws.com/`;

const processingStatusStyling = {
	width: '140px',
	height: '40px',
	alignItems: 'center',
	justifyContent: 'center',
};

const statusTextStyling = {
	whiteSpace: 'normal',
	overFlow: 'hidden',
	fontSize: '12px',
	lineHeight: '14px',
	color: '#0094FF',
};

const failedStyling = {
	color: '#e11e1e',
	marginLeft: '50px',
};

const failedEyeStyling = {
	width: '16px',
	marginRight: '6px',
	cursor: 'Pointer',
};

const titleLinkStyle = {
	color: '#CA5010',
	fontWeight: 'bold',
	textDecoration: 'underline',
};

const SuccesSlytyling = {
	color: '#107C10',
};

const retry = {
	marginLeft: '20px',
	cursor: 'pointer',
};

const syncWid = {
	width: '200px',
};

const jsfy = {
	justifyContent: 'space-between',
};

// const syncStyling = {
// 	color: '#0094FF',
// };

const customTitle = (
	<>
		Please{' '}
		<a style={titleLinkStyle} href="/warehouse-management/wms-logs">
			CLICK HERE
		</a>{' '}
		to view error details for failed invoices in the WMS logs. (Note: You need to copy the below
		invoice numbers and paste them in the WMS log - Invoice Search filters to view the details).
	</>
);

export const UploadListColumnsNew = ({
	handleClick,
	defaultSort,
	handleStatusModalVisibles,
	setErrorModalVisible,
	downloadFiles,
	downloadErrorFiles,
	defaultsortField,
	checkFileType,
}) => {
	const [pusherStatus, setPusherStatus] = useState('');
	const [pusherFileName, setPusherFileName] = useState('');
	const [fileStatus, setFIleStatus] = useState(null);

	useEffect(() => {
		setFIleStatus(false);
		const pusher = new Pusher('3b0ba2814b3106751cd9', {
			cluster: 'ap2',
		});

		const channel = pusher.subscribe('notification-channel');

		channel.bind('diwali', (data) => {
			setPusherStatus(data.message.body.status);
			setPusherFileName(data.message.body.filename);
			setFIleStatus(true);
		});
	}, []);

	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';

	return [
		{
			title: 'Uploaded File',
			dataIndex: 'fileName',
			key: 'fileName',
			overflow: 'hidden',
			width: '20%',
			render: (fileName, record) => {
				// eslint-disable-next-line no-unsafe-optional-chaining
				const filePath = s3Bucket + record?.fileName;

				return (
					<Flex
						style={{
							whiteSpace: 'normal',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						overFlow="hidden"
						fontSize="12px"
						color="#000"
					>
						<Flex>
							<Box width={30}>
								<SVGICONS.CsvIcons />
							</Box>
							<Box style={{ width: '20rem' }} cursor="pointer" color="#0077b6">
								<a href={filePath} download={filePath}>
									{fileName ? fileName.substr(fileName.lastIndexOf('/') + 1) : ''}
								</a>
							</Box>
						</Flex>
					</Flex>
				);
			},
		},
		{
			title: 'Uploaded For',
			dataIndex: 'fileType',
			key: 'fileType',
			overflow: 'hidden',
			width: '8%',
			render: (fileType, record) => {
				const count = record
					? record.totalGRNs +
					  record.totalOrders +
					  record.totalSalesReturns +
					  record.totalStockAdjustments
					: null;
				return (
					<Text overFlow="hidden" fontSize="12px" color="#000" padding="5px">
						{ADAPTER_UPLOAD_FILE_TYPE_CHOICE.filter((x) => x.id === fileType)[0]
							?.name || ''}
						{record?.fileType !== 'picklite_order_adapter' && (
							<span>&nbsp;{count ? `(${count || ''})` : ''}</span>
						)}
					</Text>
				);
			},
		},
		{
			title: 'Brand',
			dataIndex: 'brand',
			key: 'brand',
			overflow: 'hidden',
			width: '5%',
			render: (brand) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" padding="5px">
					{brand || ''}
				</Text>
			),
		},
		// {
		// 	title: 'FC',
		// 	dataIndex: 'fc',
		// 	key: 'fc',
		// 	overflow: 'hidden',
		// 	width: '5%',
		// 	render: (fc) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000" padding="5px">
		// 			{fc || ''}
		// 		</Text>
		// 	),
		// },
		{
			title: checkFileType === 'pack_master' ? null : 'FC',
			dataIndex: 'fc',
			key: 'fc',
			overflow: 'hidden',
			width: '5%',
			render: (fc, record) => {
				if (record?.fileType !== 'pack_master') {
					return (
						<Text overFlow="hidden" fontSize="12px" color="#000" padding="5px">
							{fc || ''}
						</Text>
					);
				}
				return null; // If fileType is not 'pack_master', don't render anything
			},
		},

		{
			title: 'Uploaded by',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: '10%',
			overflow: 'hidden',
			sorter: (a, b) => a.x - b.x,
			sortOrder: defaultsortField === 'createdAt' ? sortDirection : false,
			render: (x, record) => {
				const name = record.UploadedBy;

				return (
					<>
						<Box>
							<Text overFlow="hidden" fontSize="12px" color="#000" padding="5px">
								{name || ''}
							</Text>
						</Box>
						<Box>
							<Text overFlow="hidden" fontSize="12px" color="#6B6B6B" padding="5px">
								<DateFormat date={record.created_at} format="HMSA" />
							</Text>
						</Box>
					</>
				);
			},
		},
		{
			title: 'Status',
			dataIndex: 'fileStatus',
			key: 'fileStatus',
			overflow: 'hidden',
			width: '8%',
			render: (fileName, record) => {
				const getStatusName = FILE_STATUS_CHOICE.filter((x) => x.id === fileName);
				const statusName = isEmpty(getStatusName)
					? { name: 'unknown status', color: '#33b5e5', background: '#FFE7FF' }
					: getStatusName[0];
				return (
					<Box>
						<Box>
							<Flex>
								{fileStatus && null}
								{/* Status for order adapter */}
								{record.fileName === pusherFileName ? (
									<>
										{pusherStatus === 'processing' && (
											<Flex style={processingStatusStyling}>
												<Text style={statusTextStyling}>Processing...</Text>
											</Flex>
										)}

										{pusherStatus !== 'processing' && (
											<Flex
												style={{
													background:
														pusherStatus?.background || '#FFE7FF',
													width: '140px',
													height: '40px',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Text
													style={
														statusName.id === 'put'
															? { paddingRight: 19, paddingLeft: 19 }
															: { padding: 0 }
													}
													overFlow="hidden"
													fontSize="12px"
													color={pusherStatus.color || '#33b5e5'}
												>
													{pusherStatus}
												</Text>
											</Flex>
										)}
									</>
								) : (
									<>
										{statusName.id === 'processing' && (
											<Flex style={processingStatusStyling}>
												<Text style={statusTextStyling}>Processing...</Text>
											</Flex>
										)}
										{statusName.id !== 'processing' && (
											<Flex
												style={{
													background: statusName?.background || '#FFE7FF',
													width: '140px',
													height: '40px',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Text
													style={
														statusName.id === 'put'
															? { paddingRight: 19, paddingLeft: 19 }
															: { padding: 0 }
													}
													overFlow="hidden"
													fontSize="12px"
													color={statusName.color || '#33b5e5'}
												>
													{statusName.name}
												</Text>
											</Flex>
										)}
									</>
								)}
							</Flex>
						</Box>
						<Box>
							<Text overFlow="hidden" fontSize="12px" color="#6B6B6B" padding="5px">
								<DateFormat date={record?.updated_at} format="HMSA" />
							</Text>
						</Box>
					</Box>
				);
			},
		},
		{
			title: '',
			dataIndex: 'fileStatus',
			key: 'fileStatus',
			overflow: 'hidden',
			width: '10%',
			render: (fileName, record) => {
				let executionStatusJSON = {};
				try {
					executionStatusJSON =
						record && record.error_msg ? JSON.parse(record.error_msg) : {};
				} catch (error) {
					executionStatusJSON = {
						error: 'Unable to parse error.',
						details: 'Error is too large. Please contact support team for more details',
					};
				}

				const error_message = `${executionStatusJSON.error} \n ${executionStatusJSON.details}`;
				const hideEye = () => {
					if (checkFileType === 'pack_master') {
						return true;
					}
					if (
						(checkFileType === 'picklite_order_adapter' ||
							checkFileType === 'picklite_grn') &&
						hasErrorTypesRefresh.indexOf(record?.fileStatus) === -1
					) {
						return true;
					}
					if (
						checkFileType === 'sales_return' ||
						checkFileType === 'picklite_old_invoice'
					) {
						return srHideEyeTypes.includes(record.fileStatus);
					}
					return false;
				};

				const hideRefresh = hasErrorTypesRefresh.indexOf(record?.fileStatus) === -1;
				const hideDownload = hasDownloadErrorTypes.indexOf(record?.fileStatus) === -1;

				return (
					<Flex
						style={{
							whiteSpace: 'normal',
							justifyContent: 'space-around',
							alignItems: 'center',
						}}
						overFlow="hidden"
						fontSize="12px"
						color="#000"
					>
						<Box
							fontSize="18px"
							padding="5px"
							cursor="pointer"
							hidden={hideEye()}
							onClick={() => handleClick(record?.id, record?.fileType)}
						>
							<Image src={eyeIcon} alt="home" className="view_icon" />
						</Box>
						<Box
							fontSize="18px"
							padding="5px"
							style={{ color: theme.colors.error }}
							hidden={isEmpty(executionStatusJSON.error)}
						>
							<Tooltip
								title={
									error_message.length > 100
										? `${error_message?.slice(0, 75)}...`
										: error_message
								}
							>
								<Box
									onClick={() =>
										setErrorModalVisible({
											show: true,
											message: error_message,
										})
									}
								>
									<WarningOutlined />
								</Box>
							</Tooltip>
						</Box>
						<Box
							fontSize="18px"
							padding="5px"
							cursor="pointer"
							hidden={hideDownload}
							onClick={() =>
								record?.fileStatus === 'processing_error' ||
								record?.fileStatus === 'partially_processed'
									? downloadErrorFiles(record?.id)
									: downloadFiles(record)
							}
						>
							<DownloadOutlined />
						</Box>

						{hasErrorTypesRefresh && (
							<Box
								fontSize="18px"
								padding="5px"
								cursor="pointer"
								hidden={hideRefresh}
								onClick={() =>
									handleStatusModalVisibles({
										title: record?.fileName
											? `File: ${record?.fileName.substr(
													// eslint-disable-next-line no-unsafe-optional-chaining
													record?.fileName?.lastIndexOf('/') + 1
											  )}`
											: '',
										filename: record?.fileName,
										user_id: record?.uploadedById,
										fileType: record?.fileType,
										id: record?.id,
									})
								}
							>
								<SyncOutlined />
							</Box>
						)}
					</Flex>
				);
			},
		},
		{
			title:
				checkFileType === 'sales_return' || checkFileType === 'picklite_old_invoice'
					? null
					: 'Sync Status',
			dataIndex: 'stockone_sync',
			key: 'stockone_sync',
			width: '25%',
			overflow: 'hidden',
			render: (stockone_sync, record) => {
				if (
					record?.fileType === 'sales_return' ||
					record?.fileType === 'picklite_old_invoice'
				) {
					return null;
				}
				return <Box>{getSynBlock(record, setErrorModalVisible)}</Box>; // If
			},
		},
	];
};

const disabledStock = {
	color: '#b61414',
};

// eslint-disable-next-line consistent-return
const getSynBlock = (record, setErrorModalVisible) => {
	if (record?.stockone_enabled === false) {
		return <Box style={disabledStock}>StockOne is disabled</Box>;
	}
	if (record?.fileStatus === 'processing_error') {
		return <Box></Box>;
	}
	if (record?.fileStatus === 'validation_error') {
		return <Box></Box>;
	}
	if (record?.stockone_enabled) {
		return <Box>{getSyncStatus(record.stockone_sync, record.id, setErrorModalVisible)}</Box>;
	}
};

const getSyncStatus = (syncStats, id, setErrorModalVisible) => {
	if (syncStats?.sync_in_progress) {
		return getSyncSync(syncStats, setErrorModalVisible);
	}
	if (syncStats?.failed === syncStats?.total_count) {
		return getFailedSync(syncStats, id, setErrorModalVisible);
	}
	if (syncStats.total_count === syncStats?.success) {
		return getSuccessSync();
	}
	return getPartialSync(syncStats, id, setErrorModalVisible);
};

function getFailedSync(syncStats, id, setErrorModalVisible) {
	return (
		<Flex>
			<Box>
				<SVGICONS.SyncStockOneIcon />
			</Box>
			<Box style={syncWid}>
				<Box>
					<Flex>
						<Box>
							<Col> Sync to StockOne Failed... </Col>
							<Col>
								<Flex style={jsfy}>
									<Box>
										{syncStats?.success} / {syncStats?.total_count}
									</Box>
									{+syncStats.failed > 0 && (
										<Box>
											<span style={failedStyling}>
												<span mr="2" mt="1">
													<Image
														style={failedEyeStyling}
														onClick={() => {
															console.log('failed');
															// eslint-disable-next-line consistent-return
															showErrorMsg(
																setErrorModalVisible,
																syncStats?.failed_invoices.join(
																	', '
																)
															);
														}}
														src={eyeIcon}
														alt="home"
														className="view_icon"
													/>
												</span>
												<span>Failed: {syncStats?.failed}</span>
											</span>
										</Box>
									)}
								</Flex>
							</Col>
						</Box>
					</Flex>
				</Box>
			</Box>
			<Box style={retry} width={40}>
				{!syncStats.sync_in_progress && (
					<Box
						onClick={() => {
							callRetry(id);
						}}
					>
						<Tooltip title="Retry to StockOne">
							<SVGICONS.SyncStockOneRetryIcon width={40} />
						</Tooltip>
					</Box>
				)}
			</Box>
		</Flex>
	);
}

const showErrorMsg = (setErrorModalVisible, messageText) =>
	setErrorModalVisible({
		show: true,
		message: messageText,
		title: customTitle,
	});

function getSyncSync(syncStats, setErrorModalVisible) {
	return (
		<Flex>
			<Box>
				<SVGICONS.SyncStockOneIcon />
			</Box>
			<Box style={syncWid}>
				<Col> Syncing to StockOne... </Col>
				<Col>
					<Flex style={jsfy}>
						<Box>
							<Tooltip title="Sucess">{syncStats?.success}</Tooltip> /
							<Tooltip title="Total"> {syncStats?.total_count}</Tooltip>
						</Box>
						{+syncStats.failed > 0 && (
							<Box>
								<span style={failedStyling}>
									<span mr="2" mt="1">
										<Image
											style={failedEyeStyling}
											onClick={() => {
												console.log('get sync');
												// eslint-disable-next-line consistent-return
												showErrorMsg(
													setErrorModalVisible,
													syncStats?.failed_invoices.join(', ')
												);
											}}
											src={eyeIcon}
											alt="home"
											className="view_icon"
										/>
									</span>
									<span>Failed: {syncStats?.failed}</span>
								</span>
							</Box>
						)}
					</Flex>
				</Col>
			</Box>
		</Flex>
	);
}

const callRetry = (id) => {
	console.log(id);
	const results = retryStockOneAPI({ id });
	console.log(results);
};

function getPartialSync(syncStats, id, setErrorModalVisible) {
	return (
		<Flex>
			<Box>
				<SVGICONS.SyncStockOneIcon />
			</Box>
			<Box style={syncWid}>
				<Box>
					<Flex>
						<Box>
							<Col> Sync to StockOne Partially... </Col>
							<Col>
								<Flex style={jsfy}>
									<Box>
										{syncStats?.success} / {syncStats?.total_count}
									</Box>
									{+syncStats.failed > 0 && (
										<Box>
											<span style={failedStyling}>
												<span mr="2" mt="1">
													<Image
														style={failedEyeStyling}
														onClick={() => {
															console.log('get partial');
															// eslint-disable-next-line consistent-return
															showErrorMsg(
																setErrorModalVisible,
																syncStats?.failed_invoices.join(
																	', '
																)
															);
														}}
														src={eyeIcon}
														alt="home"
														className="view_icon"
													/>
												</span>
												<span>Failed: {syncStats?.failed}</span>
											</span>
										</Box>
									)}
								</Flex>
							</Col>
						</Box>
					</Flex>
				</Box>
			</Box>
			<Box style={retry} width={40}>
				{!syncStats.sync_in_progress && (
					<Box
						onClick={() => {
							callRetry(id);
						}}
					>
						<Tooltip title="Retry to StockOne">
							<SVGICONS.SyncStockOneRetryIcon width={40} />
						</Tooltip>
					</Box>
				)}
			</Box>
		</Flex>
	);
}

function getSuccessSync() {
	return (
		<Flex>
			<Box>
				<SVGICONS.SyncStockOneIcon />
			</Box>
			<Box>
				<Col style={SuccesSlytyling}> Synced to StockOne successfully </Col>
			</Box>
		</Flex>
	);
}

const getInvoiceNo = (data = '') => (
	<Box overFlow="hidden" fontSize="12px" color="#000">
		{data}
	</Box>
);

const invoice_name = 'Invoice no';

export const UploadViewListColumnsNew = [
	{
		title: invoice_name,
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		overflow: 'hidden',
		sorter: (a, b) => a.invoice_no - b.invoice_no,
		render: (invoice_no) => getInvoiceNo(invoice_no),
	},
	{
		title: 'Store',
		dataIndex: 'Store',
		key: 'Store',
		overflow: 'hidden',
		width: '30%',
		render: (Store) => (
			<Box style={{ whiteSpace: 'normal' }} overFlow="hidden" fontSize="12px" color="#000">
				{Store?.name || ''} ({Store?.code ? `${Store?.code}` : ''})
			</Box>
		),
	},
	{
		title: 'Salesman',
		dataIndex: 'Salesman',
		overflow: 'hidden',
		width: '30%',
		render: (Salesman) => (
			<Box style={{ whiteSpace: 'normal' }} overFlow="hidden" fontSize="12px" color="#000">
				{Salesman?.name || ''} ({Salesman?.code ? `${Salesman?.code}` : ''})
			</Box>
		),
	},
];

export const GrnUploadViewListColumnsNew = [
	{
		title: invoice_name,
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		overflow: 'hidden',
		render: (invoice_no) => getInvoiceNo(invoice_no),
	},
	{
		title: 'Brand PO Date',
		dataIndex: 'brand_grn_date',
		key: 'brand_grn_date',
		overflow: 'hidden',
		render: (brand_grn_date) => (
			<Box overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={brand_grn_date} />
			</Box>
		),
	},
	{
		title: 'Brand GRN No',
		dataIndex: 'brand_grn_no',
		key: 'brand_grn_no',
		overflow: 'hidden',
		render: (brand_grn_no) => getInvoiceNo(brand_grn_no),
	},
	{
		title: 'PO Value',
		dataIndex: 'grn_value',
		key: 'grn_value',
		overflow: 'hidden',
		render: (grn_value) => getInvoiceNo(grn_value),
	},
];

export const BrandSalesReturnListColumns = [
	{
		title: invoice_name,
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		overflow: 'hidden',
		render: (invoice_no) => getInvoiceNo(invoice_no),
	},
	{
		title: 'Store',
		dataIndex: 'store_name',
		key: 'store_name',
		overflow: 'hidden',
		width: '30%',
		render: (store_name) => getInvoiceNo(store_name),
	},

	{
		title: 'Return no',
		dataIndex: 'return_no',
		key: 'return_no',
		overflow: 'hidden',
		render: (return_no) => getInvoiceNo(return_no),
	},
	{
		title: 'Error',
		dataIndex: 'error',
		key: 'error',
		overflow: 'hidden',
		width: '30%',
		render: (error) => getInvoiceNo(error),
	},
];
