import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { SideBarMenus, checkAssignedGroups } from 'utils';

export default function useACL() {
	const { auth } = useSelector((state) => state?.auth);

	// in the first map we are filtering the childrens allowed groups,
	// later we filter for elem which has no children.
	// If user is admin we are allowing them to view all the menus
	const validSidebarMenus =
		auth?.user?.is_superuser || ['System Admin'].includes(auth?.user?.groups?.[0]?.name)
			? SideBarMenus.filter((x) => !x?.excludedroute)
			: SideBarMenus.map((x) => {
					if (!isEmpty(x.children)) {
						return {
							...x,
							children: x.children.filter((y) =>
								checkAssignedGroups(y.allowedgroups, auth?.user)
							),
						};
					}
					return x;
			  }).filter((x) => {
					if (!isEmpty(x.children)) {
						return true;
					}
					return checkAssignedGroups(x.allowedgroups, auth?.user);
			  });

	return {
		sidebarMenus: validSidebarMenus,
	};
}
